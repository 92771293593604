import { default as businesscaseLFNfbjMTVkMeta } from "/companion/pages/businesscase.vue?macro=true";
import { default as CustomContextPadMewxDKarqUMeta } from "/companion/pages/CustomControls/CustomContextPad.js?macro=true";
import { default as CustomPalettebp3SddUNk4Meta } from "/companion/pages/CustomControls/CustomPalette.js?macro=true";
import { default as indexpLnRRIGW6IMeta } from "/companion/pages/CustomControls/index.js?macro=true";
import { default as CustomContextPadProviderrhL5Xup6KgMeta } from "/companion/pages/CustomModeler/Custom/CustomContextPadProvider.js?macro=true";
import { default as CustomPalettekVd0NBEx9kMeta } from "/companion/pages/CustomModeler/Custom/CustomPalette.js?macro=true";
import { default as indexNnQBQgjmHiMeta } from "/companion/pages/CustomModeler/Custom/index.js?macro=true";
import { default as indexCYHxbT5uQCMeta } from "/companion/pages/CustomModeler/index.js?macro=true";
import { default as indexEOd7ZG4UXRMeta } from "/companion/pages/CustomTranslate/index.js?macro=true";
import { default as translationseRXW7kXxkSMeta } from "/companion/pages/CustomTranslate/translations.js?macro=true";
import { default as indexbC27GwMkbbMeta } from "/companion/pages/index.vue?macro=true";
import { default as projectlBpgou2zRbMeta } from "/companion/pages/project.vue?macro=true";
import { default as trustworthyFCD7nP0SggMeta } from "/companion/pages/trustworthy.vue?macro=true";
import { default as welcomePageXQd1ouojVtMeta } from "/companion/pages/welcomePage.vue?macro=true";
import { default as workflowKlhpGjWcLWMeta } from "/companion/pages/workflow.vue?macro=true";
export default [
  {
    name: businesscaseLFNfbjMTVkMeta?.name ?? "businesscase",
    path: businesscaseLFNfbjMTVkMeta?.path ?? "/businesscase",
    meta: businesscaseLFNfbjMTVkMeta || {},
    alias: businesscaseLFNfbjMTVkMeta?.alias || [],
    redirect: businesscaseLFNfbjMTVkMeta?.redirect || undefined,
    component: () => import("/companion/pages/businesscase.vue").then(m => m.default || m)
  },
  {
    name: CustomContextPadMewxDKarqUMeta?.name ?? "CustomControls-CustomContextPad",
    path: CustomContextPadMewxDKarqUMeta?.path ?? "/CustomControls/CustomContextPad",
    meta: CustomContextPadMewxDKarqUMeta || {},
    alias: CustomContextPadMewxDKarqUMeta?.alias || [],
    redirect: CustomContextPadMewxDKarqUMeta?.redirect || undefined,
    component: () => import("/companion/pages/CustomControls/CustomContextPad.js").then(m => m.default || m)
  },
  {
    name: CustomPalettebp3SddUNk4Meta?.name ?? "CustomControls-CustomPalette",
    path: CustomPalettebp3SddUNk4Meta?.path ?? "/CustomControls/CustomPalette",
    meta: CustomPalettebp3SddUNk4Meta || {},
    alias: CustomPalettebp3SddUNk4Meta?.alias || [],
    redirect: CustomPalettebp3SddUNk4Meta?.redirect || undefined,
    component: () => import("/companion/pages/CustomControls/CustomPalette.js").then(m => m.default || m)
  },
  {
    name: indexpLnRRIGW6IMeta?.name ?? "CustomControls",
    path: indexpLnRRIGW6IMeta?.path ?? "/CustomControls",
    meta: indexpLnRRIGW6IMeta || {},
    alias: indexpLnRRIGW6IMeta?.alias || [],
    redirect: indexpLnRRIGW6IMeta?.redirect || undefined,
    component: () => import("/companion/pages/CustomControls/index.js").then(m => m.default || m)
  },
  {
    name: CustomContextPadProviderrhL5Xup6KgMeta?.name ?? "CustomModeler-Custom-CustomContextPadProvider",
    path: CustomContextPadProviderrhL5Xup6KgMeta?.path ?? "/CustomModeler/Custom/CustomContextPadProvider",
    meta: CustomContextPadProviderrhL5Xup6KgMeta || {},
    alias: CustomContextPadProviderrhL5Xup6KgMeta?.alias || [],
    redirect: CustomContextPadProviderrhL5Xup6KgMeta?.redirect || undefined,
    component: () => import("/companion/pages/CustomModeler/Custom/CustomContextPadProvider.js").then(m => m.default || m)
  },
  {
    name: CustomPalettekVd0NBEx9kMeta?.name ?? "CustomModeler-Custom-CustomPalette",
    path: CustomPalettekVd0NBEx9kMeta?.path ?? "/CustomModeler/Custom/CustomPalette",
    meta: CustomPalettekVd0NBEx9kMeta || {},
    alias: CustomPalettekVd0NBEx9kMeta?.alias || [],
    redirect: CustomPalettekVd0NBEx9kMeta?.redirect || undefined,
    component: () => import("/companion/pages/CustomModeler/Custom/CustomPalette.js").then(m => m.default || m)
  },
  {
    name: indexNnQBQgjmHiMeta?.name ?? "CustomModeler-Custom",
    path: indexNnQBQgjmHiMeta?.path ?? "/CustomModeler/Custom",
    meta: indexNnQBQgjmHiMeta || {},
    alias: indexNnQBQgjmHiMeta?.alias || [],
    redirect: indexNnQBQgjmHiMeta?.redirect || undefined,
    component: () => import("/companion/pages/CustomModeler/Custom/index.js").then(m => m.default || m)
  },
  {
    name: indexCYHxbT5uQCMeta?.name ?? "CustomModeler",
    path: indexCYHxbT5uQCMeta?.path ?? "/CustomModeler",
    meta: indexCYHxbT5uQCMeta || {},
    alias: indexCYHxbT5uQCMeta?.alias || [],
    redirect: indexCYHxbT5uQCMeta?.redirect || undefined,
    component: () => import("/companion/pages/CustomModeler/index.js").then(m => m.default || m)
  },
  {
    name: indexEOd7ZG4UXRMeta?.name ?? "CustomTranslate",
    path: indexEOd7ZG4UXRMeta?.path ?? "/CustomTranslate",
    meta: indexEOd7ZG4UXRMeta || {},
    alias: indexEOd7ZG4UXRMeta?.alias || [],
    redirect: indexEOd7ZG4UXRMeta?.redirect || undefined,
    component: () => import("/companion/pages/CustomTranslate/index.js").then(m => m.default || m)
  },
  {
    name: translationseRXW7kXxkSMeta?.name ?? "CustomTranslate-translations",
    path: translationseRXW7kXxkSMeta?.path ?? "/CustomTranslate/translations",
    meta: translationseRXW7kXxkSMeta || {},
    alias: translationseRXW7kXxkSMeta?.alias || [],
    redirect: translationseRXW7kXxkSMeta?.redirect || undefined,
    component: () => import("/companion/pages/CustomTranslate/translations.js").then(m => m.default || m)
  },
  {
    name: indexbC27GwMkbbMeta?.name ?? "index",
    path: indexbC27GwMkbbMeta?.path ?? "/",
    meta: indexbC27GwMkbbMeta || {},
    alias: indexbC27GwMkbbMeta?.alias || [],
    redirect: indexbC27GwMkbbMeta?.redirect || undefined,
    component: () => import("/companion/pages/index.vue").then(m => m.default || m)
  },
  {
    name: projectlBpgou2zRbMeta?.name ?? "project",
    path: projectlBpgou2zRbMeta?.path ?? "/project",
    meta: projectlBpgou2zRbMeta || {},
    alias: projectlBpgou2zRbMeta?.alias || [],
    redirect: projectlBpgou2zRbMeta?.redirect || undefined,
    component: () => import("/companion/pages/project.vue").then(m => m.default || m)
  },
  {
    name: trustworthyFCD7nP0SggMeta?.name ?? "trustworthy",
    path: trustworthyFCD7nP0SggMeta?.path ?? "/trustworthy",
    meta: trustworthyFCD7nP0SggMeta || {},
    alias: trustworthyFCD7nP0SggMeta?.alias || [],
    redirect: trustworthyFCD7nP0SggMeta?.redirect || undefined,
    component: () => import("/companion/pages/trustworthy.vue").then(m => m.default || m)
  },
  {
    name: welcomePageXQd1ouojVtMeta?.name ?? "welcomePage",
    path: welcomePageXQd1ouojVtMeta?.path ?? "/welcomePage",
    meta: welcomePageXQd1ouojVtMeta || {},
    alias: welcomePageXQd1ouojVtMeta?.alias || [],
    redirect: welcomePageXQd1ouojVtMeta?.redirect || undefined,
    component: () => import("/companion/pages/welcomePage.vue").then(m => m.default || m)
  },
  {
    name: workflowKlhpGjWcLWMeta?.name ?? "workflow",
    path: workflowKlhpGjWcLWMeta?.path ?? "/workflow",
    meta: workflowKlhpGjWcLWMeta || {},
    alias: workflowKlhpGjWcLWMeta?.alias || [],
    redirect: workflowKlhpGjWcLWMeta?.redirect || undefined,
    component: () => import("/companion/pages/workflow.vue").then(m => m.default || m)
  }
]